<template>
    <v-card min-height="130">
        <v-card-title>
            {{ $vuetify.lang.t('$vuetify.pages.dashboard.totalInspectors') }}
        </v-card-title>
        <v-card-text v-if="loaded" class="text-h3">{{ item.total_inspectors }}</v-card-text>
        <v-progress-circular v-else indeterminate></v-progress-circular>
    </v-card>
</template>

<script>
export default {
    name: "KurccInspectorsAnalytics",
    data() {
        return {
            loaded: false,
            item: [],
        }
    },
    created() {
        this.$store.dispatch('getInspectorsAnalytics').then(item => {
            this.item = item
            this.loaded = true
        })
    }
}
</script>
